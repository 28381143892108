import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  Connection,
  SystemProgram,
  TransactionMessage,
  VersionedTransaction,
  Enum,
} from "@solana/web3.js";
import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import Layout from "../../layouts";
import { enqueueSnackbar } from "notistack";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { ParticleNetwork } from "@particle-network/auth";
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import axios from "axios";
import {EventBus, BUS_EVENT} from '../../eventBus'
import {throttle} from 'loadsh'

import {
  useConnection,
  useWallet,
  ConnectionContext,
  WalletContext,
} from "@solana/wallet-adapter-react";
import { config } from "process";



const TO_PUBLIC_KEY = "CZmVK1DymrSVWHiQCGXx6VG5zgHVrh5J1P514jHKRDxA";
const BASE_URL = 'https://api.infinitytest.cc/api/v1';

const WALLET_TYPE = {
  "OKX Wallet": "okx",
  "Solflare": "solflare",
  "Trust": "trust",
  "Phantom": "phantom",
}

export default function HomePage() {
  const { publicKey, sendTransaction, wallet } = useWallet();
  const [balance, setBalance] = useState(0);
  const [toPublicKey, setToPublicKey] = useState(TO_PUBLIC_KEY);
  const [toCount, setToCount] = useState(10000000);
  const { connection } = useConnection();
  const { setVisible, disconnect , visible } = useWalletModal();

  const onToPublicKey = (e) => {
    setToPublicKey(e.target.value);
  };
  useEffect(() => {
    //refresh()
    const tg = window?.Telegram?.WebApp;
    if(tg){
      tg.ready();
      tg.expand();
    }
    // 先初始化, 再全屏设置

    window.startCanvas()
    window.eventBus = new EventBus();
    window.gameLoaded = false
    window?.eventBus?.subscribe('GAME_VIEW_LOADED', async (data) => {
      await wallet?.adapter?.disconnect()
      window.gameLoaded = true
    });
    window?.eventBus?.subscribe('USE_WALLET_CONNECT', async (data) => {
      // window.solanaWallet = wallet
      // await window.solanaWallet?.adapter?.disconnect()
    });
    window.walletLoginState = false
    window.successGetWalletRes = false

  }, []);

  const refresh = async (event) => {
    await window.walletDisconnect()
    window.solanaWallet = null
    window.walletDisconnect = null
    window.solanaPublicKey = null
  };

  const getWalletUserId = async () =>{
    if(window.solanaPublicKey){
      postToAuth({
        walletChain: 'solana',
        walletAddress: window.solanaPublicKey,
        walletType: window.solanaWallet.adapter.name
      }, '/user/auth/wallet_login')
    }
  }

    //!particle相关
  // 定义一个发送 HTTPS 请求的方法
  const postToAuth = (data, path) => {
    if(window.walletPosting){
      return
    }
    window.walletPosting = true
    window.eventBus.publish(BUS_EVENT.SHOW_LOADING);
    axios.post(`${BASE_URL}${path}`, data)
      .then((response) => {
        console.log('Response:', response.data);
        window.walletPosting = false
        //游戏登录页面
        window.eventBus.publish(BUS_EVENT.WALLET_LOGIN, response.data);
      })
      .catch((error) => {
        window.walletPosting = false
        window.walletLoginState = false
        console.error('Error:', error);
      });
  };

  const getParticleUserId = async () =>{
    const particleInfo = await getUserInfo()
    window.walletType = 'particle'
    postToAuth({
      token: particleInfo?.token,
      uuid: particleInfo?.uuid,
      loginChannel:'CAT_GAME'
    }, '/user/auth/particle_login')
  }

  const getUserInfo = async ()=>{
    const particle = new ParticleNetwork({
      projectId: "23999e66-348d-470b-810b-4e73428f42fa",
      clientKey: "cTIzRYUk6QqxwVbcQAnhytC5EmLDwNn7Mw4zBffV",
      appId: "14af93a7-5c9b-43d9-9f13-55736847bfc0",
    })
    await particle.auth.login(
      {
        preferredAuthType: "email",
        supportAuthTypes: "email"
      }
    );
    particle.setChainInfo({
      id: 102,
      name: 'solana',
    });

    let userInfo;
    if (!particle.auth.isLogin()) {
        userInfo = particle.auth.getUserInfo();
    } else {
        userInfo = particle.auth.userInfo();
    }
    window.particleWallet = userInfo.wallets[0];
    return userInfo
  }

  const particleLogout = async ()=>{
    const particle = new ParticleNetwork({
      projectId: "23999e66-348d-470b-810b-4e73428f42fa",
      clientKey: "cTIzRYUk6QqxwVbcQAnhytC5EmLDwNn7Mw4zBffV",
      appId: "14af93a7-5c9b-43d9-9f13-55736847bfc0",
    });

    let userInfo;
    if (!particle.auth.isLogin()) {
        userInfo = await particle.auth.login();
        userInfo = particle.auth.getUserInfo();
    } else {
        userInfo = particle.auth.logout()
    }
    return userInfo
  }
  window.particle_login = getUserInfo
  window.particle_logout = particleLogout
  window.getParticleUserId = getParticleUserId;
  window.getWalletUserId = getWalletUserId
  window.walletsOpen = setVisible
  window.solanaWallet = wallet
  if(window.solanaWallet?.adapter?.connected && window.gameLoaded && !visible){
    window.solanaWallet = wallet
    window.walletDisconnect = ()=> { 
      window.solanaWallet?.adapter?.disconnect()
    }
    window.solanaPublicKey = window.solanaWallet?.adapter?.publicKey?.toBase58()
    postToAuth({
      walletChain: 'solana',
      walletAddress: window.solanaPublicKey,
      walletType: WALLET_TYPE[window.solanaWallet.adapter.name] ? WALLET_TYPE[window.solanaWallet.adapter.name] : window.solanaWallet.adapter.name
    }, '/user/auth/wallet_login')
    window.successGetWalletRes = true

  }
  //window.openWalletS = WalletMultiButton.openModal

  const onToCount = (e) => {
    setToCount(e.target.value * LAMPORTS_PER_SOL);
  };

  const onTransfer = async () => {
    enqueueSnackbar(`transfer to ${toPublicKey} ${toCount} SOL`);
    enqueueSnackbar(`SystemProgram: ${SystemProgram.programId.toBase58()}`);
    const txInstructions = [
      SystemProgram.transfer({
        fromPubkey: publicKey, //this.publicKey,
        toPubkey: new PublicKey(toPublicKey), //destination,
        lamports: toCount, //amount,
      }),
    ];

    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext();
    //let latestBlockhash = await connection.getLatestBlockhash("finalized");
    enqueueSnackbar(
      `   ✅ - Fetched latest blockhash. Last Valid Height: 
      ${lastValidBlockHeight}`
    );
    console.log("slot:", minContextSlot);
    console.log("latestBlockhash:", blockhash);

    const messageV0 = new TransactionMessage({
      payerKey: publicKey,
      recentBlockhash: blockhash,
      instructions: txInstructions,
    }).compileToV0Message();

    const trx = new VersionedTransaction(messageV0);
    const signature = await sendTransaction(trx, connection, {
      minContextSlot,
    });
    console.log("signature:", signature);
  };

  const onBalance = () => {
    console.log("wallet is ", publicKey);
    connection.getBalance(publicKey).then((balance) => {
      enqueueSnackbar(`${publicKey} has a balance of ${balance}`);
      setBalance(balance);
    });
  };

  return (
    <div className="container">

      <div id="GameDiv">
    <canvas id="GameCanvas"></canvas>
  </div>
    </div>
    
  );
  // return (
  //     <Box
  //       sx={{
  //         bgcolor: "background.paper",
  //         pt: 8,
  //         pb: 6,
  //       }}
  //     >
  //       <Container maxWidth="sm">
  //         <Typography
  //           component="h1"
  //           variant="h2"
  //           align="center"
  //           color="text.primary"
  //           gutterBottom
  //         >
  //           Wallet Adatpter
  //         </Typography>
  //         <Typography
  //           variant="h5"
  //           align="center"
  //           color="text.secondary"
  //           paragraph
  //         >
  //           Modular TypeScript wallet adapters and components for Solana
  //           applications.
  //         </Typography>
    

  //         <Stack
  //           sx={{ pt: 4 }}
  //           direction="row"
  //           spacing={2}
  //           justifyContent="center"
  //         >
  //           <Container>
  //           <div id="GameDiv">
  //   <canvas id="GameCanvas"></canvas>
  // </div>
  //             <React.Fragment>
  //               <span>Balance:{balance / LAMPORTS_PER_SOL} </span>
  //               <Button onClick={onBalance}> Query Balance </Button>
  //             </React.Fragment>
  //             <React.Fragment>
  //               <div>
  //                 <TextField label="To" onChange={onToPublicKey} />
  //                 <TextField label="Count" onChange={onToCount} />
  //                 <Button onClick={onTransfer}> Transfer </Button>
  //               </div>
  //             </React.Fragment>
  //           </Container>
  //         </Stack>
  //       </Container>
  //     </Box>
  // );
}
